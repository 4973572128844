<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-0"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Empresa (entidad facturante) -->
                  <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresaItems"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      @change="
                        setComprobantesByEntFac(
                          empresaSelected != null ? empresaSelected.id : -1
                        )
                      "
                      clearable
                      dense
                      autocomplete="off"
                      label="Entidad facturante"
                      hint="Nombre"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- tipo de fecha -->
                  <v-col cols="3" md="3" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="itemsTiposFecha"
                      return-object
                      label="Tipo de fecha"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha desde -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Tipo comprobante -->
                  <v-col cols="12" sm="4" md="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tipoComprobantesItems"
                      item-text="value"
                      item-value="id"
                      return-object
                      label="Tipo de comprobante"
                      outlined
                      multiple
                      dense
                      clearable
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="selectAllItems">
                          <v-list-item-action>
                            <v-icon :color="isSelectedAll ? 'primary' : ''">
                              {{
                                isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('CAN')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('CAN') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("CAN") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo cancelatorios
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('DE')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('DE') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("DE") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo de deudas
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="selectByDescription('REC')">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                isSelectedByDescription('REC') || isSelectedAll
                                  ? 'primary'
                                  : ''
                              "
                            >
                              {{
                                isSelectedByDescription("REC") || isSelectedAll
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Solo recibos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ tipoComprobanteSelected.length - 1 }} otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Letra comprobante -->
                  <v-col cols="12" sm="1" md="1" class="py-0">
                    <v-text-field
                      type="text"
                      class="center-input"
                      v-model="letraComprobante"
                      outlined
                      dense
                      persistent-hint
                      v-mask="'A'"
                      hint="Letra"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- sucursal comprobante -->
                  <v-col cols="12" sm="1" md="1" class="py-0">
                    <v-text-field
                      type="text"
                      v-model="sucursalCompr"
                      outlined
                      dense
                      v-mask="'####'"
                      persistent-hint
                      hint="Suc."
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- numero comprobante -->
                  <v-col cols="12" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      v-model="nroComprobante"
                      outlined
                      dense
                      v-mask="'########'"
                      label="Número comprobante"
                      persistent-hint
                      hint="Número comprobante"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Convenios -->
                  <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                      ref="convenios-autocomplete"
                      class="p-0"
                      v-model="convenioSelected"
                      :items="itemsConvenio"
                      @change="loadSubconvenios()"
                      label="Convenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Agente de facturacion -->
                  <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                      ref="agentes-autocomplete"
                      v-model="agenteFacturacionSelected"
                      :items="agentesFacturacionItems"
                      :search-input.sync="agentesFacturaAutocompleteInput"
                      item-text="value1"
                      item-value="id"
                      :filter="customFilterAgentes"
                      outlined
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Agente de facturación"
                      hint="Nombre, CUIT o código"
                      persistent-hint
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value1
                          }}</v-list-item-title>
                          <v-list-item-subtitle
                            >Código: {{ item.value2 }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="agentesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- periodo -->
                  <v-col cols="12" md="2" class="py-0">
                    <v-text-field
                      type="text"
                      v-model="periodoComp"
                      outlined
                      dense
                      label="Período"
                      :rules="rules.periodoYyyyMm"
                      v-mask="'######'"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <!-- Switch Anulados -->
                  <v-col cols="6" md="2" class="py-0">
                    <v-switch
                      class="mt-0"
                      v-model="anuladosSwitch"
                      label="Anulados"
                      :disabled="conSaldoSwitch"
                    ></v-switch>
                  </v-col>
                  <!-- Switch Con saldo -->
                  <v-col cols="6" md="2" class="py-0 pr-0">
                    <v-switch
                      class="mt-0"
                      v-model="conSaldoSwitch"
                      label="Con saldo al:"
                      :disabled="anuladosSwitch"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha con saldo al: (esta fecha va a ser siempre la misma de "fechaHasta" y va siempre disabled)-->
                  <v-col
                    v-if="conSaldoSwitch"
                    cols="12"
                    sm="3"
                    md="3"
                    class="py-0"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menuFechaSaldo"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="formato DD/MM/AAAA"
                          @keydown.tab="menuFechaSaldo = false"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          @blur="
                            [(fechaDesde = parseDateToIso(fechaHastaSelected))]
                          "
                          @change="
                            [(fechaDesde = parseDateToIso(fechaHastaSelected))]
                          "
                          clearable
                          dense
                          disabled
                          outlined
                          autocomplete="off"
                          :rules="
                            conSaldoSwitch
                              ? rules.required.concat(rules.validDate)
                              : []
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        scrollable
                        @change="
                          [(fechaHastaSelected = formatDate(fechaDesde))]
                        "
                        @input="menuFechaSaldo = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- SubConvenios -->
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    v-if="this.paramGralSubConvenio"
                  >
                    <v-autocomplete
                      ref="subConvenios-autocomplete"
                      class="p-0"
                      v-model="subConvenioSelected"
                      :items="itemsSubConvenio"
                      :disabled="this.haveConvenioSelected"
                      label="Subconvenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Monedas -->
                  <v-col
                    cols="12"
                    class="py-0"
                    md="3"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedaItems"
                      :rules="rules.required"
                      label="Moneda de consulta"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha Cotiza -->
                  <v-col
                    cols="12"
                    md="3"
                    class="py-0"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-menu
                      ref="fecha-cotiza"
                      v-model="menuFechaCotiza"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaCotizaSelected"
                          label="Fecha cotización"
                          :append-icon="calendarIcon"
                          :disabled="cotizarFechaCompSwitch"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaCotiza = parseDateToIso(fechaCotizaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            !cotizarFechaCompSwitch
                              ? rules.required.concat(rules.validDate)
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaCotiza"
                        no-title
                        @change="fechaCotizaSelected = formatDate(fechaCotiza)"
                        @input="menuFechaCotiza = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Cotizar a la fecha de comp. -->
                  <v-col v-if="this.paramGralMultimoneda" cols="3" class="py-0">
                    <v-switch
                      class="mt-n2"
                      v-model="cotizarFechaCompSwitch"
                      @change="setFechaCotizaBySwitch"
                      label="Cotizar a la fecha de comprobante"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    :disabled="!isFormValid"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
            <p
              :class="
                showFilters
                  ? 'invalidConfigText mb-n1'
                  : 'invalidConfigText mb-n1 ml-2'
              "
              v-if="this.haveCotiza && this.paramGralMultimoneda"
            >
              Existen comprobantes sin cotización
            </p>
          </v-container>
        </v-card>
        <v-card>
          <v-row>
            <v-col col="9" class="pt-0 pb-0" align="start" align-self="center">
              <v-btn-toggle dense v-model="text" color="primary" group>
                <v-btn value="DETALLE COMPROBANTES" @click="switchTab(1)">
                  Detalle comprobantes
                </v-btn>
                <v-btn @click="switchTab(2)"> Totales por agente </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <!-- TABLA DE DETALLES -->
          <section v-if="showDetallesTable">
            <v-data-table
              :headers="headersDetallesTable"
              :items="itemsDetalles"
              height="100%"
              fixed-header
              class="elevation-1"
              :loading="isLoadingTable"
              item-key="pfCompId"
            >
              <template
                v-if="itemsDetalles.length > 0"
                v-slot:[`body.append`]="{ headers }"
              >
                <tr>
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'fechaVenc'">Total</div>
                    <div
                      :style="{ 'text-align': 'right' }"
                      v-if="header.value == 'importeTotalToShow'"
                    >
                      <strong> ${{ sumatoriaImportesDetalle }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="goToVerComprobante(item)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </template>
                  <span>Ver comprobante</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </section>
          <!-- TABLA DE TOTALES -->
          <section v-if="showTotalesTable">
            <v-data-table
              :headers="headersTotalesTable"
              :items="itemsTotales"
              height="100%"
              fixed-header
              class="elevation-1"
              :loading="isLoadingTable"
              item-key="agenteId"
            >
              <template
                v-if="itemsTotales.length > 0"
                v-slot:[`body.append`]="{ headers }"
              >
                <tr class="totales">
                  <td v-for="(header, i) in headers" :key="i">
                    <div v-if="header.value == 'ageFactNombre'">Total</div>
                    <div
                      class="to-right"
                      v-if="header.value == 'importeTotalToShow'"
                    >
                      <strong> ${{ sumatoriaImportesTotales }}</strong>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </section>
        </v-card>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="6" md="7" align="right" class="py-3">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsDetalles.length == 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import helpersExcel from "@/utils/helpers/importExportExcel";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
export default {
  name: "ComprobantesFacturacionOS",
  components: {
    Ayuda,
    FiltersSelected,
    PageHeader,
  },
  directives: { mask },
  data: () => ({
    rules: rules,
    title: "Comprobantes Facturación OS",
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    seeIcon: enums.icons.SEE,
    calendarIcon: enums.icons.CALENDAR,
    optionCode: enums.webSiteOptions.CONSULTA_COMPROBANTES_FACTURACIONOS,
    search: "",
    text: "DETALLE COMPROBANTES",
    allowedActions: null,
    filtersApplied: [],
    filtrosGuardados: {},
    empresaSelected: null,
    empresaItems: [],
    agenteFacturacionSelected: null,
    agentesFacturacionItems: [],
    agentesFacturaAutocompleteInput: null,
    convenioSelected: null,
    itemsConvenio: [],
    subConvenioSelected: null,
    itemsSubConvenio: [],
    monedaSelected: null,
    monedaItems: [],
    sucursalCompr: "",
    letraComprobante: "",
    nroComprobante: "",
    tipoFechaSelected: null,
    itemsTiposFecha: [
      { id: 1, value: "Fecha de comprobante", value2: "FC" },
      { id: 2, value: "Fecha de vencimiento", value2: "FV" },
    ],
    conSaldoSwitch: false,
    anuladosSwitch: false,
    cotizarFechaCompSwitch: false,
    menuFechaDesde: false,
    menuFechaHasta: false,
    fechaDesdeSelected: null,
    fechaHastaSelected: null,
    menuFechaSaldo: null,
    fechaDesde: null,
    fechaHasta: null,
    menuFechaCotiza: false,
    fechaCotizaSelected: null,
    fechaCotiza: null,
    periodoComp: null,
    sumatoriaImportesDetalle: null,
    sumatoriaImportesTotales: null,
    tipoComprobanteSelected: [],
    tipoComprobantesItems: [],
    itemsTotales: [],
    headersTotalesTable: [
      {
        text: "Agente",
        sortable: false,
        value: "ageFactNombre",
        align: "start",
      },
      {
        text: "Importe total",
        sortable: false,
        value: "importeTotalToShow",
        align: "right",
      },
    ],
    itemsDetalles: [],
    headersDetallesTable: [
      {
        text: "Agente de facturación",
        sortable: false,
        value: "agenteNombre",
      },
      {
        text: "Número",
        sortable: false,
        value: "comprobanteNro",
      },
      {
        text: "Fecha",
        sortable: false,
        value: "fechaComprobante",
      },
      {
        text: "Fecha de vencimiento",
        sortable: false,
        value: "fechaVenc",
      },
      {
        text: "Importe",
        sortable: false,
        align: "end",
        value: "importeTotalToShow",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
    showHelp: false,
    isFormValid: false,
    showFilters: true,
    showExpand: false,
    isLoadingTable: false,
    agentesLoading: false,
    paramGralMultimoneda: false,
    paramGralSubConvenio: false,
    haveConvenioSelected: true,
    setInitialParameter: true,
    showDetallesTable: true,
    showTotalesTable: false,
    haveCotiza: false,
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setSelects();
  },
  computed: {
    isSelectedAll() {
      return (
        this.tipoComprobanteSelected.length ===
        this.tipoComprobantesItems.length
      );
    },
  },
  watch: {
    agentesFacturaAutocompleteInput(val) {
      const value = val.trimLeft();
      if (value) {
        this.getAgentesFacturaFilter();
      }
    },
  },
  async mounted() {
    if (this.$store.state.filtersFacturacion != null) {
      this.empresaSelected = this.$store.state.filtersFacturacion.EntFacId;
      this.fechaDesde = this.$store.state.filtersFacturacion.FechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersFacturacion.FechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      if (this.$store.state.filtersFacturacion.AgeFactId != null) {
        const responseArray = await this.fetchAgentesFacturacionByQuery({
          input: this.$store.state.filtersFacturacion.AgeFactId.value1,
        });
        this.agentesFacturacionItems = responseArray;
        this.agenteFacturacionSelected = this.agentesFacturacionItems[0];
      }
      this.tipoComprobanteSelected = this.$store.state.filtersFacturacion.TipoComprobanteId;
      this.convenioSelected = this.$store.state.filtersFacturacion.ConvenioId;
      if (this.convenioSelected) this.haveConvenioSelected = false;
      this.subConvenioSelected = this.$store.state.filtersFacturacion.SubConvenioId;
      this.loadSubconvenios();
      this.tipoFechaSelected = this.$store.state.filtersFacturacion.TipoFecha;
      this.periodoComp = this.$store.state.filtersFacturacion.Periodo;
      this.letraComprobante = this.$store.state.filtersFacturacion.Letra;
      this.sucursalCompr = this.$store.state.filtersFacturacion.Sucursal;
      this.nroComprobante = this.$store.state.filtersFacturacion.NroComprobante;
      this.anuladosSwitch = this.$store.state.filtersFacturacion.Anulados;
      this.conSaldoSwitch = this.$store.state.filtersFacturacion.FechaSaldo;
      this.monedaSelected = this.$store.state.filtersFacturacion.MonedaId;
      this.fechaCotiza = this.$store.state.filtersFacturacion.FechaCotiza;
      this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      this.cotizarFechaCompSwitch =
        this.$store.state.filtersFacturacion.CotizaFechaComprobante;
      this.applyFilters();
    }
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getAllTipoComprobantesFacturacionOS:
        "facturacionOS/getAllTipoComprobantesFacturacionOS",
      fetchAgentesFacturacionByQuery:
        "facturacionOS/fetchAgentesFacturacionByQuery",
      getComprobantesFacturacionOSByFilters:
        "facturacionOS/getComprobantesFacturacionOSByFilters",
      getSubconveniosByConvenio: "afiliaciones/getSubconveniosByConvenio",
      getConvenios: "afiliaciones/getConvenios",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParametroGralFechaDesde: "proveedores/getParametroGralFechaDesde",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getParametrosGralesBenef: "afiliaciones/getParametrosGralesBenef",
    }),
    async setSelects() {
      this.setComprobantesByEntFac(-1);
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresaItems = entidadesFacturantes;
      const convenios = await this.getConvenios();
      this.itemsConvenio = convenios;
      if (this.empresaItems.length == 1) {
        this.empresaSelected = this.empresaItems[0];
      }
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      if (this.setInitialParameter) {
        //solo seteo para pantalla inicial el tipo fecha, la moneda vigente y fecha desde/hasta
        this.tipoFechaSelected = this.itemsTiposFecha[0];
        const monedaVig = monedasResponse.find((x) => x.bool == true);
        this.monedaSelected = monedaVig;
        this.setFechaInicial();
      }
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const responseParamSubConv = await this.getParametrosGralesBenef();
      this.paramGralSubConvenio = responseParamSubConv.subConvenio;
    },
    async setFechaInicial() {
      // fecha de hoy para seteo inicial de fecha hasta
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      // seteo inicial de fecha desde la base de datos
      const response = await this.getParametroGralFechaDesde();
      const FechaBaseDatos = response[0].fecDesdePorDefecto;
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.fechaCotiza = `${año}-${mes}-${dia}`;
      this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      this.fechaDesde = this.parseDateToIso(FechaBaseDatos);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    async setComprobantesByEntFac(entFacId) {
      const tipoComprobanteFacturacionOS =
        await this.getAllTipoComprobantesFacturacionOS(entFacId);
      this.tipoComprobantesItems = tipoComprobanteFacturacionOS;
    },
    getAgentesFacturaFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.agentesFacturacionItems?.some(
            (x) =>
              x.value2 == this.agentesFacturaAutocompleteInput ||
              x.value1 == this.agentesFacturaAutocompleteInput
          )
        ) {
          return;
        } else {
          this.agentesLoading = true;
          const response = await this.fetchAgentesFacturacionByQuery({
            input: this.agentesFacturaAutocompleteInput,
          });
          this.agentesFacturacionItems = response;
          this.agentesLoading = false;
        }
      }, 1000);
    },
    customFilterAgentes(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    async loadSubconvenios() {
      if (this.convenioSelected != null) {
        this.haveConvenioSelected = false;
        const response = await this.getSubconveniosByConvenio(
          this.convenioSelected.id
        );
        //saco del array el item que es sin dato
        const itemsSinDato = response.filter((x) => x.id != 0);
        this.itemsSubConvenio = itemsSinDato;
      } else {
        this.haveConvenioSelected = true;
        this.subConvenioSelected = null;
      }
    },
    async applyFilters() {
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const responseParamSubConv = await this.getParametrosGralesBenef();
      this.paramGralSubConvenio = responseParamSubConv.subConvenio;
      this.setInitialParameter = false;
      this.customizeFiltersApplied();
      this.isLoadingTable = true;
      this.haveCotiza = false;
      this.showFilters = false;
      this.$store.state.filtersFacturacion = null;
      this.sumatoriaImportesDetalle = null;
      this.sumatoriaImportesTotales = null;
      const filters = {
        EntFacId: this.empresaSelected ? this.empresaSelected.id : null,
        FechaDesde: this.fechaDesde,
        FechaHasta: this.fechaHasta,
        AgeFactId: this.agenteFacturacionSelected
          ? this.agenteFacturacionSelected.id
          : null,
        TipoComprobanteId: this.tipoComprobanteSelected.map((x) => x.id),
        ConvenioId: this.convenioSelected ? this.convenioSelected.id : null,
        SubConvenioId: this.subConvenioSelected
          ? this.subConvenioSelected.id
          : null,
        TipoFecha: this.tipoFechaSelected.id,
        Periodo: this.periodoComp == "" ? null : this.periodoComp,
        NroComprobante:
          this.letraComprobante == "" &&
          this.nroComprobante == "" &&
          this.sucursalCompr == ""
            ? null
            : //se quitan los 0 antepuestos del numero y suc. para que filtre de todas formas.
              `${this.letraComprobante} - ${this.sucursalCompr.replace(
                /^0+/,
                ""
              )} - ${this.nroComprobante.replace(/^0+/, "")}`,
        Anulados: this.anuladosSwitch,
        FechaSaldo: this.conSaldoSwitch ? this.fechaHasta : null,
        MonedaId: this.paramGralMultimoneda ? this.monedaSelected.id : -1,
        FechaCotiza: this.paramGralMultimoneda ? this.fechaCotiza : null,
        CotizaFechaComprobante: this.cotizarFechaCompSwitch,
      };
      this.filtrosGuardados = {
        EntFacId: this.empresaSelected,
        FechaDesde: this.fechaDesde,
        FechaHasta: this.fechaHasta,
        AgeFactId: this.agenteFacturacionSelected,
        TipoComprobanteId: this.tipoComprobanteSelected,
        ConvenioId: this.convenioSelected,
        SubConvenioId: this.subConvenioSelected,
        TipoFecha: this.tipoFechaSelected,
        Periodo: this.periodoComp,
        Letra: this.letraComprobante,
        Sucursal: this.sucursalCompr,
        NroComprobante: this.nroComprobante,
        Anulados: this.anuladosSwitch,
        FechaSaldo: this.conSaldoSwitch,
        MonedaId: this.paramGralMultimoneda ? this.monedaSelected : null,
        FechaCotiza: this.paramGralMultimoneda ? this.fechaCotiza : null,
        CotizaFechaComprobante: this.cotizarFechaCompSwitch,
      };
      try {
        const response = await this.getComprobantesFacturacionOSByFilters(
          filters
        );
        this.itemsTotales = response.totales;
        this.itemsDetalles = response.detalleComprobante;
        //calculo totales que van debajo de las tablas
        this.itemsDetalles.forEach((x) => {
          this.sumatoriaImportesDetalle += x.importeTotalToEdit;
        });
        this.itemsTotales.forEach((x) => {
          this.sumatoriaImportesTotales += x.importeTotalToEdit;
        });
        this.sumatoriaImportesDetalle = this.formatDecimalMoney(
          this.sumatoriaImportesDetalle
        );
        this.sumatoriaImportesTotales = this.formatDecimalMoney(
          this.sumatoriaImportesTotales
        );
        const anyObjectWithCotiza = response.detalleComprobante.some(
          (x) => x.haveCotiza == 0
        );
        if (anyObjectWithCotiza) this.haveCotiza = true;
        this.customizeFiltersApplied();
        this.isLoadingTable = false;
        this.showFilters = false;
      } catch {
        this.isLoadingTable = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "empresaSelected",
          label: "Entidad facturante",
          model: this.empresaSelected.value,
        });
      }
      if (this.agenteFacturacionSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "agenteFacturacionSelected",
          label: "Agente de facturación",
          model: this.agenteFacturacionSelected.value1,
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected,
        });
      }
      if (this.convenioSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "convenioSelected",
          label: "Convenio",
          model: this.convenioSelected.value,
        });
      }
      if (this.subConvenioSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "subConvenioSelected",
          label: "Subconvenio",
          model: this.subConvenioSelected.value,
        });
      }
      if (this.fechaDesde) {
        this.filtersApplied.splice(5, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.formatDate(this.fechaDesde),
        });
      }
      if (this.fechaHasta) {
        this.filtersApplied.splice(6, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.formatDate(this.fechaHasta),
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(7, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.letraComprobante || this.sucursalCompr || this.nroComprobante) {
        this.filtersApplied.splice(8, 1, {
          key: "nroComprobante",
          label: "Número comprobante",
          model: `${this.letraComprobante} - ${this.sucursalCompr} - ${this.nroComprobante}`,
        });
      }
      if (this.periodoComp) {
        this.filtersApplied.splice(9, 1, {
          key: "periodoComp",
          label: "Período",
          model: this.periodoComp,
        });
      }
      if (this.anuladosSwitch) {
        this.filtersApplied.splice(10, 1, {
          key: "anuladosSwitch",
          label: "Anulados",
          model: this.anuladosSwitch ? "SI" : "NO",
        });
      }
      if (this.conSaldoSwitch) {
        this.filtersApplied.splice(11, 1, {
          key: "conSaldoSwitch",
          label: "Con saldo al",
          model: this.fechaHastaSelected,
        });
      }
      if (this.monedaSelected && this.paramGralMultimoneda) {
        this.filtersApplied.splice(12, 1, {
          key: "monedaSelected",
          label: "Moneda de consulta",
          model: this.monedaSelected.value,
        });
      }
      if (this.fechaCotiza && this.paramGralMultimoneda) {
        this.filtersApplied.splice(13, 1, {
          key: "fechaCotiza",
          label: "Fecha cotización",
          model: this.formatDate(this.fechaCotiza),
        });
      }
      if (this.cotizarFechaCompSwitch && this.paramGralMultimoneda) {
        this.filtersApplied.splice(14, 1, {
          key: "cotizarFechaCompSwitch",
          label: "Cotizar a la fecha del comprobante",
          model: this.cotizarFechaCompSwitch ? "SI" : "NO",
        });
      }
    },
    formatDecimalMoney(valor) {
      const formatter = new Intl.NumberFormat("es-ES", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(valor);
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    setFechaCotizaBySwitch() {
      if (this.cotizarFechaCompSwitch) {
        this.fechaCotizaSelected = null;
        this.fechaCotiza = null;
      }else{
        let now = new Date();
        let año = now.getFullYear();
        let mes = now.getMonth() + 1;
        let dia = now.getDate();
        dia < 10 ? (dia = `0${dia}`) : dia;
        mes < 10 ? (mes = `0${mes}`) : mes;
        this.fechaCotiza = `${año}-${mes}-${dia}`;
        this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      }
    },
    exportExcelModelo() {
      let result = [];
      if (this.showDetallesTable) {
        this.itemsDetalles?.forEach((x) => {
          const comprobantesDetalle = {
            ["Entidad facturante"]: x.entFacNombre,
            ["Agente de facturación"]: x.agenteNombre,
            ["Número"]: x.comprobanteNro,
            ["Fecha"]: x.fechaComprobante,
            ["Fecha de vencimiento"]: x.fechaVenc,
            ["Importe"]: x.importeTotalToShow,
            ["Período"]: x.periodo,
            ["Convenio"]: x.convenioNombre,
          };
          // Verifica si parametro gral subconvenio esta activado para insertar ese dato
          if(this.paramGralSubConvenio) comprobantesDetalle["SubConvenio"] = x.subConvNombre;
          // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
          if (this.paramGralMultimoneda) {
            comprobantesDetalle["Moneda del comprobante"] = x.monedaNombre == "" ? "PESO" : x.monedaNombre.toUpperCase();
            comprobantesDetalle["Moneda de consulta"] = this.filtrosGuardados.MonedaId.value.toUpperCase();
            comprobantesDetalle["Fecha cotización"] = this.cotizarFechaCompSwitch
              ? x.fechaComprobante
              : this.formatDate(this.filtrosGuardados.FechaCotiza);
          }
          result.push(comprobantesDetalle);
        });
      } else {
        this.itemsTotales?.forEach((x) =>
          result.push({
            ["Agente"]: x.ageFactNombre,
            ["Importe"]: x.importeTotalToShow,
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Comprobantes facturacion OS");
    },
    switchTab(num) {
      switch (num) {
        case 1:
          this.showDetallesTable = true;
          this.showTotalesTable = false;
          break;
        case 2:
          this.showTotalesTable = true;
          this.showDetallesTable = false;
          break;
      }
    },
    goToVerComprobante(item) {
      if (item.agru == "A") {
        this.$router.push({
          name: "DetalleComprobanteFacturacionOS",
          params: {
            pfCompId: item.pfCompId,
            monedaToDetalle: this.filtrosGuardados.MonedaId,
            fechaCotizaToDetalle: this.cotizarFechaCompSwitch
              ? this.parseDateToIso(item.fechaComprobante)
              : this.filtrosGuardados.FechaCotiza,
            goBackName: "ComprobantesFacturacionOS",
          },
        });
        this.$store.state.filtersFacturacion = this.filtrosGuardados;
      } else if (item.agru == "B") {
        this.$router.push({
          name: "DetalleComprobanteCancelaFacturacionOS",
          params: {
            pfCompId: item.pfCompId,
            monedaToDetalle: this.filtrosGuardados.MonedaId,
            fechaCotizaToDetalle: this.cotizarFechaCompSwitch
              ? this.parseDateToIso(item.fechaComprobante)
              : this.filtrosGuardados.FechaCotiza,
            goBackName: "ComprobantesFacturacionOS",
          },
        });
        this.$store.state.filtersFacturacion = this.filtrosGuardados;
      }
    },
    selectAllItems() {
      if (this.isSelectedAll) {
        this.tipoComprobanteSelected = [];
      } else {
        this.tipoComprobanteSelected = this.tipoComprobantesItems;
      }
    },
    selectByDescription(description) {
      const filteredItems = this.tipoComprobantesItems.filter(
        (item) => item.description === description
      );
      if (filteredItems.length === 0) {
        return; // Si no hay elementos coincidentes, salir
      }
      if (this.isSelectedByDescription(description)) {
        // si ya esta seleccionado, se quitan
        this.tipoComprobanteSelected = this.tipoComprobanteSelected.filter(
          (item) => item.description !== description
        );
      } else {
        // si no estan seleccionados, se agregan concatenando los dos arrays
        this.tipoComprobanteSelected = [
          ...this.tipoComprobanteSelected,
          ...filteredItems,
        ];
      }
    },
    isSelectedByDescription(description) {
      //aca nos fijamos si el array de tipo comprobantes tiene los items que coincidan con los de la description pasada por parametro
      const matchingItems = this.tipoComprobantesItems.filter(
        (item) => item.description === description
      );
      return matchingItems.every((item) =>
        this.tipoComprobanteSelected.some((x) => x.id == item.id)
      );
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
.invalidConfigText {
  color: red;
}
.label {
  opacity: 0.7;
}
</style>